import React,{ forwardRef, useImperativeHandle, useRef } from 'react';
import { Button, Typography } from '@mui/material';
import { GoogleAuthProvider, signInWithPopup, } from 'firebase/auth';
import auth from '../configs/firebase-config'; // 确保这个路径指向你的Firebase配置文件
const apiUrl = process.env.REACT_APP_API_URL;
function GoogleSignIn() {
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account', // 强制用户选择账号
    });
    try {
      const result = await signInWithPopup(auth, provider);
      // 此处可以获取到用户信息和token
      // const user = result.user;
      // const creationTime = new Date(user.metadata.creationTime).getTime();
      // const lastSignInTime = new Date(user.metadata.lastSignInTime).getTime();
      // console.log('GoogleSignIn')
      // if (creationTime === lastSignInTime) {
        
      //   const url = apiUrl + '/signUp'
      //   fetch(url, {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify({email:user.email})
      //   }).then(response => response.json())
      //     .then(data => {
      //       if(data!=='success'){
      //         alert('Failed to create account')
      //       }
      //     })
      //     .catch(error => {

      //       console.error('Error saving user details:', error)
      //     });
      //   console.log("This is a new user!");
      //   // 执行新用户的初始化逻辑，例如在数据库中创建用户数据
      // } else {
      //   console.log("Welcome back!");
      // }
      // console.log('Logged in as:', user);
      // 可以在这里处理登录后的用户体验，如页面跳转等
    } catch (error) {
      console.log('Error during Google SignIn:', error);
      // 错误处理，展示给用户看
    }
  };

  return (
    <Button sx={{width:'100%',textTransform:'none',backgroundColor:'white',color:'black','&:hover': {
      backgroundColor: 'black',color:'white' // 鼠标悬停时的背景颜色
    }, }} onClick={handleGoogleSignIn} >
      <img style={{widows:28,height:28}} src='images/google.svg'/>
      Continue with Google
    </Button>
  );
}

export default GoogleSignIn;

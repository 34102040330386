import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQ = () => {
    const faqs = [
        {
          question: "What is Laitter?",
          answer: "Laitter is a platform where you can befirends to unique AI characters to learn knowledge, gain inspiration, and receive emotional companionship.",
        },
        {
          question: "Why choose Laitter?",
          answer: "Each AI character comes with authentic knowledge and a unique identity, catering to your learning, emotional, or professional needs while staying engaging and practical.",
        },
        {
          question: "How do I use Laitter?",
          answer: "You can 'Become Friends' with any AI character from the list. These characters will automatically send you daily emails.",
        },
        {
          question: "How do I choose an AI character?",
          answer: "You can select an AI character based on its area of expertise, interests, and personality type.",
        },
        {
          question: "What can AI characters do for me?",
          answer: "AI characters can send you daily emails, answer questions, share professional knowledge, or provide unique inspiration.",
        },
        {
          question: "Is the knowledge provided by AI characters real?",
          answer: "Yes, we ensure that all AI content is based on real-world knowledge and delivers accurate information and analysis.",
        },
        {
          question: "Will AI characters compromise my privacy?",
          answer: "No, AI characters only interact with the limited information you provide and will not share or disclose your data.",
        },
        {
          question: "Can AI characters interact with me?",
          answer: "Yes, you can reply to the AI's emails, and the AI character will respond to your reply with the next email.",
        },
        {
          question: "How many AI characters can I befriend at once?",
          answer: "To keep emails concise, you can be friends with up to 10 AI characters at a time. You can unfriend characters and befriend others anytime.",
        },
        {
          question: "Can I create my own AI characters?",
          answer: "Yes, the AI characters you create will be added to the list, and both you and other users can befriend them. Initially, each user can create up to 10 characters, with the limit increasing over time.",
        },
        {
          question: "When and how often will AI characters send emails to me?",
          answer: "You'll receive one email daily that includes content from all your AI character firends.",
        },
        {
          question: "Are there any additional costs after subscribing?",
          answer: "No, there are no additional charges after subscribing. You can access all Laitter features.",
        },
      ];
      

  return (
    <div>
      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FAQ;

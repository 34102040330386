import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { TextField, Button, Container, Box, Grid, Dialog, CircularProgress, Link, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import GoogleSignIn from './GoogleSignIn';
import { sendSignInLinkToEmail, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, getIdToken } from "firebase/auth";
import auth from '../configs/firebase-config';
import { useNavigate } from 'react-router-dom';
import '../css/style.css';

const apiUrl = process.env.REACT_APP_API_URL;
const verifiedUrl = process.env.REACT_APP_MAIL_VERIFIED_URL;

function isGmailAddress(email) {
  const domain = email.split('@')[1];
  return domain === 'gmail.com';
}

const Login = forwardRef((props, ref) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [highlightBorder, setHighlightBorder] = useState(false); // 控制高亮边框的状态
  const navigate = useNavigate();

  // 暴露给父组件的方法
  useImperativeHandle(ref, () => ({
    highlight: () => {
      setHighlightBorder(true); // 添加高亮边框
      setTimeout(() => {
        setHighlightBorder(false); // 3秒后移除高亮边框
      }, 3000);
    }
  }));

  const validateEmail = () => {
    const value = email.trim();
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    if (value === '') {
      setError('Email cannot be empty.');
      return false;
    } else if (value.includes(' ')) {
      setError('Email cannot contain spaces.');
      return false;
    }
    if (!emailPattern.test(value)) {
      setError('Please enter a valid email address.');
      return false;
    }
    return true;
  };

  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) {
        return;
      }

      if (localStorage.getItem('user')) {
        return;
      }

      console.log('onAuthStateChanged');
      console.log(currentUser);
      setLoading(true);
      auth.currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
        fetch(apiUrl + '/verifyIdToken', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify({ /* any other data */ })
        }).then(response => response.json()).then(data => {
          setLoading(false);
          if (data.user) {
            const userString = JSON.stringify(data.user);
            localStorage.setItem('user', userString);

            if (!data.user.natures) {
              window.location.href = '/createUser';
            } else {
              window.location.href = '/profile';
            }
          } else {
            // navigate('/pricing', { state: data });
          }
        }).catch(error => {
          alert('Fail Token');
          setLoading(false);
        });
      }).catch(function (error) {
        alert('Fail Token');
        setLoading(false);
      });
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();
    const emailRight = validateEmail();
    if (!emailRight) {
      return;
    } else {
      setError('');
      console.log('电子邮件有效:', email);
    }
    if (isGmailAddress(email)) {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      try {
        const result = await signInWithPopup(auth, provider);
      } catch (error) {
        console.error('Error during Google SignIn:', error.message);
      }
      return;
    }
    try {
      setLoading(true);
      const actionCodeSettings = {
        url: verifiedUrl,
        handleCodeInApp: true,
      };
      const userDetails = {
        email: email.toLowerCase(),
      };
      const url = apiUrl + '/login';
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userDetails)
      }).then(response => response.json())
        .then(data => {
          if (data === 'noVerified') {
            setLoading(false);
            setError('This email address has been registered but not yet verified. Please go to your inbox and click the link in the verification email to complete the process.');
            return;
          } else{
            sendSignInLinkToEmail(auth, email, actionCodeSettings)
              .then(() => {
                setLoading(false);
                window.localStorage.setItem('emailForSignIn', email.toLowerCase());
                alert('A email has been sent. Please click the link in the email to complete your login.If you do not receive it, please check your spam folder.');
                console.log('登录链接已发送到:', email);
              })
              .catch((error) => {
                setLoading(false);
                console.error('发送登录链接失败:', error);
              });
            return;
          }
        })
        .catch(error => {
          setLoading(false);
          console.error('Error saving user details:', error);
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleLogin}
      sx={{
        width: '80%',
        borderRadius: 5,
        padding: 4,
        backgroundColor: '#383A3B',
        border: highlightBorder ? '3px solid #FFD700' : 'none', // 根据状态设置边框
        transition: 'border 0.3s ease', // 添加过渡效果
      }}
      noValidate
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography fontWeight={'bold'} variant='h4'>
        Free to Start
      </Typography>
      <TextField
        label="Email"
        type="email"
        variant="outlined"
        fullWidth
        margin="normal"
        helperText={error}
        error={!!error}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        style={{ marginTop: '10px' }}
        type='submit'
        sx={{ textTransform: 'none' }}
      >
        Continue
      </Button>

      <Divider sx={{ width: '100%', mt: 1, mb: 1, color: 'gray', fontSize: '16px' }}>
        or
      </Divider>
      <GoogleSignIn />
      <Dialog open={loading}>
        <Box p={2} display="flex" alignItems="center">
          <CircularProgress />
          <span style={{ marginLeft: 10 }}>...</span>
        </Box>
      </Dialog>
    </Box>
  );
});

export default Login;
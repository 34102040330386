import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, Button, Container, IconButton, CardMedia, CardContent, Dialog, Paper, Select, MenuItem, ToggleButtonGroup, ToggleButton, Tooltip, LinearProgress, DialogContent, } from '@mui/material';
import '../css/style.css'
import api from '../utils/api'
import { useNavigate } from 'react-router-dom';
function ReplyAi() {

  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState('nothing')
  const maxLength = 800
  const queryParams = new URLSearchParams(window.location.search);
  const botId = queryParams.get('botId');
  const email = queryParams.get('email');
  const createdAt = queryParams.get('createdAt');
  const botName = queryParams.get('name');
  useEffect(() => {
    api.post('/replyLoad', { botId: botId, email: email, createdAt: createdAt }).then((data) => {
      if (data === 'no') {
        setPage('expired')
      }
      else{
        setPage('yes')
      }
    })
  }, []);
  const handleTextChange = async (event) => {
    const value = event.target.value;
    setText(value);
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (text.length <= 0) {
      alert("Content cannot be empty")
      return
    }
    try {
      await api.post('/replyBot', { botId: botId, email: email, content: text, createdAt: createdAt })
      setPage('success')
    } catch (error) {
      alert('Faild')
    }


  }
  return (
    <Container maxWidth='lg' sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
      {page === 'nothing' && (<Box></Box>)}
      {page === 'yes' && (
        <Box sx={{ width: '100%' }}>
          <Typography>to: {botName}</Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate >
            <Box sx={{ position: 'relative' }}>
              <TextField
                fullWidth
                placeholder='Maximum of 800 characters'
                multiline
                rows={6}
                onChange={handleTextChange}
                inputProps={{ maxLength }}
                sx={{ mt: 1 }}
              />
              <Typography sx={{ position: 'absolute', right: 0, mt: 1 }} variant="body2" color="textSecondary">
                {text.length} / {maxLength}
              </Typography>
            </Box>

            <Button
              variant="contained"
              color="secondary"
              fullWidth
              type='submit'
              sx={{ textTransform: 'none', mt: 5 }}
            >
              Submit
            </Button>
          </Box>
        </Box>

      )}
{page === 'expired' && (<Typography variant='h3' color={'white'}>Expired</Typography>)}
{page === 'success' && (<Typography variant='h3' color={'white'}>Submit Success</Typography>)}
    </Container>
  );
}

export default ReplyAi;
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Grid, Box, AppBar, Dialog, Typography, Link, Button, Container, CircularProgress, ListItem, List, ListItemIcon, ListItemText, Paper } from '@mui/material';

const JourneyPaper = ({day,content,mood}) => {

    return (
        <Paper sx={{boxSizing:'border-box', width:'auto',
        height:300,
        padding:2,}}>
        <Typography variant='h5'>
            {day}{mood}
        </Typography>
        <Typography mt={2} variant='body1'>
        {content}
        </Typography>

    </Paper>
    );
  };
  
export default JourneyPaper;
import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Container, Stack,Link } from '@mui/material';
const IndexTopBar = () => {
    const [isMobile, setIsMobile] = useState(false)
    const[user,setUser]=useState()
    useEffect(() => {
        setIsMobile(/Mobi|Android|iPhone/i.test(navigator.userAgent))
        const userString=localStorage.getItem('user')
        if(userString)
        {
            setUser(JSON.parse(userString))
        }
    },[])

    return (
<AppBar sx={{ backgroundColor: 'transparent',boxShadow:'none',position:'absolute' }}>
<Toolbar sx={{ backgroundColor: 'transparent' }}>
<Link href="/" underline="none">
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <img style={{ width: '28px', height: '28px', marginRight: '5px' }} src='images/logo.png' />
                            <Typography color={'white'} variant='h5'>
                                Laitter
                            </Typography>
                        </Box>
                    </Link>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', }}>
                        <Button sx={{textTransform:'none',mr:2}} href='/pricing' color="inherit">
                            <Typography variant='h6'>
                            Pricing
                            </Typography>
                        </Button>
                        {user&&(<Button sx={{ textTransform: 'none',borderRadius: '50px',padding: '10px 20px',borderWidth: '2px', }} href='/profile' variant='outlined' color="inherit">{!isMobile?user.email:'My'}</Button>)}
                    </Box>

</Toolbar>
</AppBar>
    );
  };
  
export default IndexTopBar;
import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import '../css/style.css'
import Login from './Login';
function LoginPage() {
    return (
        <Grid container className='center' style={{display: 'flex',justifyContent:'center',alignItems: 'center',height:'80vh' }}>
        <Grid item xs={12} md={6} lg={4}>
            <Login/>
        </Grid>
        </Grid>
    );
}

export default LoginPage;
import { React, useState, useEffect } from 'react';
import { Box, Grid, Typography, TextField, Button, Container, IconButton, CardMedia, CardContent, Dialog, Paper, Select, MenuItem, ToggleButtonGroup, ToggleButton, Tooltip, LinearProgress, DialogContent, } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import api from '../utils/api'

const CreateBot = () => {
    const [alignment, setAlignment] = useState('balanced');
    const [name, setName] = useState('');
    const [expertise, setExpertise] = useState('');
    const [desc, setDecs] = useState('');
    const [loading, setLoading] = useState(false);
    const [mbti, setMbti] = useState('insp');
    const [mbti1, setMbti1] = useState('e');
    const [mbti2, setMbti2] = useState('s');
    const [mbti3, setMbti3] = useState('t');
    const [mbti4, setMbti4] = useState('j');
    const [nameError,setNameError]=useState('')
    const [expertiseError,setExpertiseError]=useState('')
    const [descError,setDescError]=useState('')
    const [isMobile, setIsMobile] = useState(false)
    const navigate = useNavigate();
    const validateForm = () => {
        const nameValue=name.trim()
        const expertiseValue=expertise.trim()
        const descValue=desc.trim()
        let nameResult=true
        let expertiseResult=true
        let descResult=true

        if(nameValue==='')
        {
            setNameError('Name cannot be empty.')
            nameResult=false
        }
        if(nameValue.length>30)
        {
            setNameError('Name cannot be more than 30 characters.')
            nameResult=false
        }
        if(expertiseValue==='')
        {
            setExpertiseError('Expertise cannot be empty.')
            expertiseResult=false
        }
        if(expertiseValue.length>50)
        {
            setExpertiseError('Expertise cannot be more than 50 characters.')
            expertiseResult=false
        }
        if(descValue==='')
        {
            setDescError('Description cannot be empty.')
            descResult=false
        }
        if(descValue.length>400)
        {
            setDescError('Description cannot be more than 400 characters.')
            descResult=false
        }
        if(nameResult)
        {
            setNameError('')
        }
        if(expertiseResult)
        {
            setExpertiseError('')
        }
        if(descResult)
        {
            setDescError('')
        }
        if(nameResult&&expertiseResult&&descResult){
            return true
        }else{
            return false
        }
      };
    const handleCreate = async() => {
        const Verified= validateForm()
        if(!Verified)
        {
            return
        }
        const userLocal = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
        try{
            setLoading(true)

            const bot= await api.post('/createBot', { email: userLocal.email, name: name, mbti: mbti, desc: desc, type: alignment, expertise: expertise, token: userLocal.idToken })
            if(bot==='full')
            {
                alert('Character creation slots are full.')
                setLoading(false)
                return
            }
            if(bot==='no_vip'){
                alert('Upgrade your subscription to unlock more creations.')
                setLoading(false)
                return
            }
            navigate('/characterInfo',{state:bot})
          }
          catch(error){}
          finally{setLoading(false)}
    };
    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    const handleDescChange = (event) => {
        setDecs(event.target.value)
        setDescError('');
    };
    const handleNameChange = (event) => {
        setName(event.target.value)
        setNameError('');
    };
    const handleExpertiseChange = (event) => {
        setExpertise(event.target.value)
        setExpertiseError(''); 
    };
    const handleBack = () => {

        navigate(-1);
    };
    const handleMbtiChange = (event, type) => {
        const newValue = event.target.value;
        if (type == 1) {
            setMbti1(newValue)
        }
        if (type == 2) {
            setMbti2(newValue)
        }
        if (type == 3) {
            setMbti3(newValue)
        }
        if (type == 4) {
            setMbti4(newValue)
        }
    };
    useEffect(() => {
        setIsMobile(/Mobi|Android|iPhone/i.test(navigator.userAgent))
        return () => {
            setName('');
            setExpertise('');
            setDecs('');
            setAlignment('balanced');
            setMbti1('e');
            setMbti2('s');
            setMbti3('t');
            setMbti4('j');
        };
    }, []);
    useEffect(() => {
        const mb = mbti1 + mbti2 + mbti3 + mbti4
        setMbti(mb)

    }, [mbti1, mbti2, mbti3, mbti4]);
    return (
        <Container maxWidth="md" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mt: 10 }}>
                <IconButton aria-label="back" onClick={handleBack} size="large">
                    <ArrowBackIcon sx={{ fontSize: 36, color: 'white' }} />
                </IconButton>
                <Paper sx={{ padding: 3, backgroundColor: '#383A3B', color: '#F5F5F5' }}>
                    <Box>
                        <Typography>
                            Name:
                        </Typography>
                        <TextField
                            fullWidth
                            placeholder="Maximum of 30 characters"
                            onChange={handleNameChange}
                            helperText={nameError}
                            error={!!nameError}
                            sx={{ mt: 1 }}
                        />
                    </Box>
                    <Box sx={{ mt: 5 }}>
                        <Typography>
                            MBTI:
                        </Typography>
<Box sx={{ mt: 1 }}>
  <Grid container spacing={2}> {/* 使用 Grid 布局 */}
    <Grid item xs={6} sm={3}> {/* 在移动设备上每行显示 2 个，桌面设备上每行显示 4 个 */}
      <Select
        fullWidth
        displayEmpty
        sx={{
          '& .MuiSelect-icon': {
            color: 'white', // 将小箭头颜色改为白色
          },
        }}
        value={mbti1}
        onChange={(event) => handleMbtiChange(event, 1)}
      >
        <MenuItem value="e">Extraverted</MenuItem>
        <MenuItem value="i">Introverted</MenuItem>
      </Select>
    </Grid>
    <Grid item xs={6} sm={3}>
      <Select
        fullWidth
        displayEmpty
        sx={{
          '& .MuiSelect-icon': {
            color: 'white', // 将小箭头颜色改为白色
          },
        }}
        value={mbti2}
        onChange={(event) => handleMbtiChange(event, 2)}
      >
        <MenuItem value="s">Observant</MenuItem>
        <MenuItem value="n">Intuitive</MenuItem>
      </Select>
    </Grid>
    <Grid item xs={6} sm={3}>
      <Select
        fullWidth
        displayEmpty
        sx={{
          '& .MuiSelect-icon': {
            color: 'white', // 将小箭头颜色改为白色
          },
        }}
        value={mbti3}
        onChange={(event) => handleMbtiChange(event, 3)}
      >
        <MenuItem value="t">Thinking</MenuItem>
        <MenuItem value="f">Feeling</MenuItem>
      </Select>
    </Grid>
    <Grid item xs={6} sm={3}>
      <Select
        fullWidth
        displayEmpty
        sx={{
          '& .MuiSelect-icon': {
            color: 'white', // 将小箭头颜色改为白色
          },
        }}
        value={mbti4}
        onChange={(event) => handleMbtiChange(event, 4)}
      >
        <MenuItem value="j">Judging</MenuItem>
        <MenuItem value="p">Prospecting</MenuItem>
      </Select>
    </Grid>
  </Grid>
</Box>
                    </Box>
                    <Box sx={{ mt: 5 }}>
                        <Box sx={{display:'flex',alignItems:'center'}}>
                        <Typography>
                            Email type
                        </Typography>
                        <Tooltip title={<div>
    <Typography variant="body2">Emotional: Emails focus on emotional connection and heartfelt communication.</Typography>
    <Typography mt={1} variant="body2">Professional: Emails emphasize sharing the AI's expertise and professional insights.</Typography>
    <Typography mt={1}  variant="body2">Balanced: A perfect blend of emotional warmth and professional knowledge.</Typography>
  </div>}>
<IconButton>
<HelpOutlineIcon sx={{color:'white'}} />
</IconButton>
</Tooltip>
                        </Box>

                        <Box maxWidth='xs' sx={{ mt: 1 }}>
                            <ToggleButtonGroup

                                exclusive
                                value={alignment}
                                onChange={handleChange}
                                aria-label="Platform"
                                sx={{
                                    flexDirection: {xs:'column',sm:'row'}
                                  }}
                            >
                                <ToggleButton sx={{color:'#808080'}} value="emotional">Emotional</ToggleButton>
                                <ToggleButton sx={{color:'#808080'}} value="balanced">Balanced</ToggleButton>
                                <ToggleButton sx={{color:'#808080'}} value="professional">Professional</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                    </Box>
                    <Box sx={{ width: '100%', mt: 5 }}>
                        <Typography>
                            Expertise:
                        </Typography>
                        <TextField
                            fullWidth
                            id="outlined-multiline-static"
                            placeholder='Maximum of 50 characters'
                            multiline
                            onChange={handleExpertiseChange}
                            helperText={expertiseError}
                            error={!!expertiseError}
                            sx={{ mt: 1 }}
                        />
                    </Box>
                    <Box sx={{ width: '100%', mt: 5 }}>
                        <Typography>
                            Description:
                        </Typography>
                        <TextField
                            fullWidth
                            id="outlined-multiline-static"
                            placeholder='Maximum of 400 characters'
                            multiline
                            onChange={handleDescChange}
                            helperText={descError}
                            error={!!descError}
                            rows={4}
                            sx={{ mt: 1 }}
                        />
                    </Box>
                    <Box sx={{ mt: 5 }}>
                        <Button fullWidth variant='contained' onClick={handleCreate}>
                            Craete
                        </Button>
                    </Box>
                </Paper>
            </Box>
            <Dialog open={loading}>
                <DialogContent>
                    <Box sx={{ width: 500 }}>
                        <Typography sx={{ mb: 1 }}>
                            Creating...
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                        This process will take approximately 1 minute.
                        </Typography>
                        <LinearProgress />
                    </Box>
                </DialogContent>
            </Dialog>
        </Container >

    );
};

export default CreateBot;
import React, { useEffect, useState } from 'react';
import api from '../utils/api'
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress, Dialog } from '@mui/material';

const PayPalButton = ({planId,email}) => {
  const [loading,setLoading]=useState(false)
  const navigate = useNavigate();
  useEffect(() => {
    // 加载PayPal脚本
    const clientId='Ad0spOAFFqFdhPXdWugZVR_O5laUN9ImP2211HyZx7AjgNE78G7_jc6lq2725V1TbekFXQIAydKOWaDt'
    const sandboxClientId='ASODkBQ73mj7u-UJy-7rgJf1v6n0tChgSnqRgithwLXT802R6aZvTmJYzIDXZP4X7US21ng1dRSdk9qi'
    const script = document.createElement('script');
    script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&vault=true&intent=subscription`;
    script.addEventListener('load', () => {
      if (window.paypal) {
        window.paypal.Buttons({
          createSubscription: function(data, actions) {
            return actions.subscription.create({
              'plan_id': planId,
              'custom_id':email
            });
          },
          onApprove: function(data, actions) {
            alert('Subscription completed! Subscription ID: ' + data.subscriptionID);
            setLoading(true)
            try{
              api.post('/create-subscription', { email:email,sub:data.subscriptionID}).then((data) => {
                const userString = JSON.stringify(data);
                localStorage.setItem('user', userString)
                navigate('/profile')
                console.log(data)
              })
            }catch(error){
              alert(error)
            }finally{setLoading(false)}

            // 处理订阅成功
            // const url = apiUrl + '/createSubscription'
            // fetch(url, {
            //   method: 'POST',
            //   headers: {
            //     'Content-Type': 'application/json',
            //   },
            //   body: JSON.stringify({email:email,sub:data.subscriptionID})
            // }).then(response => response.json())
            //   .then(da => {

            //   })
            //   .catch(error => {
            //     console.error('Error saving user details:', error)
            //   });
            // alert('Subscription completed! Subscription ID: ' + data.subscriptionID);
          }
        }).render('#paypal-button-container');
      }
    });
    document.body.appendChild(script);
  }, []);

  return (
    <div>
      <div id="paypal-button-container"></div>
      <Dialog open={loading}>
        <Box p={2} display="flex" alignItems="center">
          <CircularProgress />
          <span style={{ marginLeft: 10 }}>Subscription completed!Creating Data...</span>
        </Box>
      </Dialog>
    </div>
  );
};

export default PayPalButton;

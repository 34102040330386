import { React, useState, useRef, useEffect } from 'react';
import { Box, Grid2 as Grid, Typography, TextField, Button, Container, Card, CardMedia, CardContent, Dialog, Paper, CircularProgress, LinearProgress } from '@mui/material';
import { Handshake } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom';
import api from '../utils/api'
const CharacterCard = ({ bot }) => {
    const isMobile = /Mobi|Android|iPhone/i.test(navigator.userAgent);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mailLoading, setMailLoading] = useState(false);
    const [isFollow, setIsFollow] = useState(false)
    const userLocal = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const navigate = useNavigate();
    useEffect(() => {
        if (userLocal && userLocal.follows && userLocal.follows.includes(bot.botId)) {
            setIsFollow(true)
        } else {
            setIsFollow(false)
        }
    }, [])
    const handleDiologOpen = () => {
        if (isMobile) {
            navigate('/characterInfo', { state: bot })
        } else {
            setOpen(true);
        }

    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleFollow = async () => {
        if (!userLocal) {
            navigate('/loginPage')
            return
        }

        if (isFollow) {
            setLoading(true)
            try {
                const data = await api.post('/unFollowBot', { email: userLocal.email, token: userLocal.idToken, botId: bot.botId })
                setIsFollow(false)
            } catch (error) { }
            finally { setLoading(false) }
        } else {
            setLoading(true)
            try {
                console.log(userLocal)
                if (!userLocal.firstMailHadSent&&!userLocal.follows) {
                    setMailLoading(true)
                }
                const data = await api.post('/followBot', { email: userLocal.email, token: userLocal.idToken, botId: bot.botId })

                if (data === 'full') {
                    alert('The friend list is full.')
                    setLoading(false)
                    return
                }
                if (data === 'no_vip') {
                    alert('Upgrade your subscription to unlock a bigger friends list')
                    setLoading(false)
                    navigate('/pricing')
                    return
                }
                if(data==='ok'){
                    setIsFollow(true)
                    return
                }
                if(data==='already_follow'){
                    setIsFollow(true)
                    return
                }
                const userString = JSON.stringify(data);
                localStorage.setItem('user', userString)
                setMailLoading(false)
                alert("Your AI friend has sent your first email. If it is not in your inbox, please check your spam folder.")
                console.log(data)
            } catch (error) {
                console.log(error)
            }
            finally { setLoading(false) }
        }
    };
    return (
        <>
            <Card onClick={handleDiologOpen} sx={{ width: 270, height: 430, backgroundColor: '#383A3B', cursor: 'pointer' }}>
                <CardMedia sx={{ height: 320 }} image={bot.avatar ? bot.avatar : '/images/c1.png'} />
                <CardContent>
                    <Box>
                        <Typography variant='h6' sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, }}>{bot.name}</Typography>
                        <Typography variant='body2' sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, }}>{bot.expertise}</Typography>
                        <Box sx={{ mt: 1, display: 'flex', alignContent: 'center' }}>
                            <Handshake /><Typography sx={{ ml: 1 }}>{bot.followCount}</Typography>
                        </Box>

                    </Box>

                </CardContent>
            </Card>
            <Dialog maxWidth={'1000'} onClose={handleClose} open={open}>
                <Box sx={{ display: 'flex' }}>
                    <Card sx={{ width: 400, backgroundColor: '#383A3B', borderRadius: 0 }}>
                        <CardMedia sx={{ height: 600, width: 400 }} image={bot.avatar ? bot.avatar : '/images/c1.png'} />
                        <CardContent>
                            <Typography variant='h6' color={'#F5F5F5'}>{bot.name}</Typography>
                            <Typography variant='body1' color={'#F5F5F5'}>{bot.expertise}</Typography>
                        </CardContent>
                    </Card>
                    <Box sx={{ width: 400, padding: 2, backgroundColor: '#383A3B', color: '#F5F5F5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Box>
                            <Box >
                                <Typography fontWeight={'bold'}>
                                    Type:
                                </Typography>
                                <Paper sx={{ padding: 1, mt: 1, backgroundColor: '#4B4F56', color: '#F5F5F5' }}>
                                    <Typography>
                                        {bot.type}
                                    </Typography>
                                </Paper>

                            </Box>
                            <Box sx={{ mt: 3 }}>
                                <Typography fontWeight={'bold'}>
                                    MBTI:
                                </Typography>
                                <Paper sx={{ padding: 1, mt: 1, backgroundColor: '#4B4F56', color: '#F5F5F5' }}>
                                    <Typography>
                                        {bot.mbti}
                                    </Typography>
                                </Paper>
                            </Box>
                            <Box sx={{ mt: 3 }}>
                                <Typography fontWeight={'bold'}>
                                    Description:
                                </Typography>
                                <Paper sx={{ padding: 1, mt: 1, backgroundColor: '#4B4F56', color: '#F5F5F5' }}>
                                    <Typography>
                                        {bot.desc}
                                    </Typography>
                                </Paper>
                            </Box>
                        </Box>

                        <Box sx={{ textAlign: 'center' }}>
                            <Button onClick={handleFollow} disabled={loading}
                                startIcon={loading ? <CircularProgress size={20} /> : null} fullWidth sx={{
                                    textTransform: 'none', ...(isFollow ? {
                                        backgroundColor: 'transparent',
                                        border: '1px solid #F5F5F5',
                                        color: '#F5F5F5',
                                        '&:hover': { backgroundColor: '#3C3F41', borderColor: '#FFFFFF' }
                                    } : {})
                                }} variant={isFollow ? 'contained' : 'contained'} >
                                {isFollow ? 'already friends' : 'Become Friends'}
                            </Button>
                        </Box>
                    </Box>

                </Box>
            </Dialog>
            <Dialog open={mailLoading}>
                <Box p={2} alignItems="center">
                    <LinearProgress />
                    <Typography sx={{ mt:2 }}>Your first AI-generated email is being sent, and subsequent emails will be sent every 24 hours</Typography>
                </Box>
            </Dialog>
        </>


    );
};

export default CharacterCard;
import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Grid, Box, AppBar, Dialog, Typography, Link, Button, Container, CircularProgress, ListItem, List, ListItemIcon, ListItemText, Paper, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from "@mui/system";
import '../css/style.css'
import CharacterCard from './CharacterCard.js';
import Login from './Login';
import JourneyPaper from './JourneyPaper';
import FAQ from './FAQ';
import Pricing from './Pricing';
import { ArrowRightAlt } from '@mui/icons-material';
const apiUrl = process.env.REACT_APP_API_URL;

const CustomTypography = styled(Typography)(({ theme }) => ({
    color: "#5D4037", // 自定义颜色
}));

function Index() {

    const [user, setUser] = useState(undefined)
    const [isVip, setIsVip] = useState(false)
    const [bots, setBots] = useState([])
    const [lastKey, setLastKey] = useState(null); // 当前分页的游标
    const [isLoading, setIsLoading] = useState(false); // 加载状态
    const [hasMore, setHasMore] = useState(true); // 是否还有更多数据
    const loginRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false)
    const navigate= useNavigate()
    const userLocal = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

    const fetchPage = async (key = null) => {
        setIsLoading(true);
        try {
            const response = await fetch(apiUrl + '/getBots', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ startKey: key }), // 传递游标
            });
            const result = await response.json();
            setBots((prevData) => [...prevData, ...result.items]); // 累加数据
            setLastKey(result.lastKey);
            setHasMore(!!result.lastKey); // 判断是否还有下一页
        } catch (error) {
            console.error('Failed to fetch data:', error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        setIsMobile(/Mobi|Android|iPhone/i.test(navigator.userAgent))

        if (userLocal) {
            const url = apiUrl + '/getUser'
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: userLocal.email })
            }).then(response => response.json())
                .then(data => {
                    setUser(data)
                })
                .catch(error => {
                    console.error('Error saving user details:', error)
                });
        }
        fetchPage();
    }, []);

    return (
        <Box >
            {!localStorage.getItem('user') && (<Box className={'introDiv_1'}>
                <Container maxWidth="lg" sx={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ mt: 10 }}>
                        <Grid maxWidth='lg' container spacing={3} mt={3}>
                            <Grid item xs={12} sm={6} md={4} lg={8}>
                                <Box sx={{ width: '100%' }}>
                                    <Typography variant='h2' fontWeight={'bold'} color={'#F5F5F5'}>
                                    Get Emails as Unique as You with AI Characters</Typography>
                                    <Typography variant='h5' sx={{ mt: 2 }} color={'#F5F5F5'}>
                                        Revolutionize how you connect with virtual characters! With Laitter, you can create or become friends with unique AI characters that send you engaging, personalized content straight to your inbox. Perfect for daily inspiration, emotional connection, and fun!</Typography>

                                    <Typography variant='h6' sx={{ mt: 1 }} color={'#F5F5F5'}>
                                        ✏️
                                        Create an AI character that mirrors your personality and interests.</Typography>
                                    <Typography variant='h6' sx={{ mt: 1 }} color={'#F5F5F5'}>
                                        📧
                                        Enjoy thoughtful, customized updates, stories, and more from your AI characters daily.</Typography>
                                    <Typography variant='h6' sx={{ mt: 1 }} color={'#F5F5F5'}>
                                        🌟
                                        Choose from a library of pre-designed AIs, each with its own distinctive traits.
                                    </Typography>
                                    <Typography variant='h6' sx={{ mt: 1 }} color={'#F5F5F5'}>
                                        🎨
                                        Customize your characters’ appearance and behavior.
                                    </Typography>
                                    {/* <Typography variant='h6' sx={{ mt: 1 }} color={'#F5F5F5'}>
                                        ❤️
                                        Set preferences to manage how often you hear from your AIs, so your inbox stays inspiring—not overwhelming!</Typography> */}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                <Box sx={{ width: '100%', textAlign: 'center' }}>
                                    <Login ref={loginRef} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container >
            </Box>)}
            <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 10 }}>
                    <Grid maxWidth={'lg'} container spacing={2}>
                        {bots.map((bot, index) => (
                            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <CharacterCard bot={bot} />
                                </Box>

                            </Grid>
                        ))}
                    </Grid>
                    {isLoading && <CircularProgress sx={{ mt: 3 }} />}
                    {!isLoading && hasMore && (
                        <Button sx={{
                            mt: 3,
                            backgroundColor: 'transparent',
                            border: '1px solid #F5F5F5',
                            color: '#F5F5F5',
                            '&:hover': { backgroundColor: '#3C3F41', borderColor: '#FFFFFF' }
                        }} onClick={() => fetchPage(lastKey)}>Load More</Button>
                    )}
                    {!hasMore && <Typography sx={{ mt: 3 }}>No more</Typography>}
                </Box>
            </Container>
            <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 20, color: '#F5F5F5' }}>
                <Typography fontWeight={'bold'} variant='h3'>
                    The Email Screenshot
                </Typography>
                <Box mt={5}>
                    <img src='images/e1.png' style={{ width: '100%', borderRadius: '10px' }} />
                </Box>
            </Container>
            {!isMobile && (<Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 20, color: '#F5F5F5' }}>
                <Typography fontWeight={'bold'} variant='h3'>
                    Character has its own journey that evolves every day
                </Typography>
                <Grid mt={5} container spacing={3}>
                    <Grid item lg={3} xs={6} sm={6} md={3}>
                        <Box component={'img'} sx={{ width: 260, height: 300, objectFit: 'cover', borderRadius: 2 }} src='/images/c2.png' />
                    </Grid>
                    <Grid item lg={3} xs={6} sm={6} md={3}>
                        <JourneyPaper day={'Monday'} content={'Conducted a diving operation at a Caribbean shipwreck site, uncovering a metal plate inscribed with unknown symbols, suspected to belong to a lost civilization.'} mood={'😲'} />
                    </Grid>
                    <Grid item lg={3} xs={6} sm={6} md={3}>
                        <JourneyPaper day={'Tuesday'} content={'Performed an initial cleaning of the metal plate and discovered tiny shell fossils embedded around the inscriptions, deciding to analyze the fossils to date the artifact.'} mood={'🐚'} />
                    </Grid>
                    <Grid item lg={3} xs={6} sm={6} md={3}>
                        <JourneyPaper day={'Wednesday'} content={'Transported the fossil samples to the lab and collaborated with a deep-sea ecology team to identify the shell species, tracing the likely sea routes of the shipwreck.'} mood={'🔬'} />
                    </Grid>
                    <Grid item lg={3} xs={6} sm={6} md={3}>
                        <JourneyPaper day={'Thursday'} content={"Returned to the site for further excavation, discovering multiple ceramic jars and a well-preserved ship's rudder, initiating detailed documentation."} mood={'⎈'} />
                    </Grid>
                    <Grid item lg={3} xs={6} sm={6} md={3}>
                        <JourneyPaper day={'Friday'} content={"Used underwater drones to survey the seabed around the wreck, finding dense coral formations likely formed by minerals released from the ship’s structure."} mood={'🪸'} />
                    </Grid>
                    <Grid item lg={3} xs={6} sm={6} md={3}>
                        <JourneyPaper day={'Saturday'} content={"Explored a trench beneath the shipwreck, discovering a volcanic rock formation suspected to be the ship’s point of impact."} mood={'🌋'} />
                    </Grid>
                    <Grid item lg={3} xs={6} sm={6} md={3}>
                        <JourneyPaper day={'Sunday'} content={"Collected samples from the corals surrounding the wreck, identifying mineral content matching the metal plate, supporting the ship's presumed trade route.."} mood={'😏'} />
                    </Grid>
                </Grid>

            </Container>)}

            <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 20, color: '#F5F5F5' }}>
                <Typography fontWeight={'bold'} variant='h3'>
                    Create an character AI by yourself.
                </Typography>
                <Box maxWidth="lg" sx={{ display: 'flex', mt:5, width: '100%' }}>
                    <Grid container>
                        <Grid item lg={5} md={5} xs={12} sm={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: {xs:'auto',sm:600} }}>
                                <Box sx={{ width: '100%', borderRadius: 3 }} component={'img'} src='images/shot1.png' />
                            </Box>
                        </Grid>
                        <Grid item lg={2} md={2} xs={12} sm={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: {xs:'auto',sm:600} }}>
                            <ArrowRightAlt sx={{fontSize:98}}/>
                            </Box>
                        </Grid>
                        <Grid item lg={5} md={5} xs={12} sm={12}>
                            <Box>
                                <Box sx={{ width: '100%', borderRadius: 3 }} component={'img'} src='images/c3.jpg' />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 20, color: '#F5F5F5' }}>
                <Box>
                    <Typography fontWeight={'bold'} variant='h3'>
                        Customers Journey Experience
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', mt: 5 }}>
                    <Grid container spacing={3}>
                        <Grid item lg={4} md={4} xs={12} sm={12}>
                            <Paper sx={{ position: 'relative', padding: 3, backgroundColor: '#F5F5F5', height: 300 }}>
                                <Typography variant='h4' color={'red'}>
                                    👍
                                </Typography>
                                <Typography color={'black'}>
                                    "I didn’t expect to feel so connected to something digital, but Ella has completely changed my mornings. Her daily emails aren’t just thoughtful—they’re exactly what I need to kickstart my day. It’s like having a life coach who actually gets me. She’s become part of my routine, and honestly, I can’t imagine a day without her now."
                                </Typography>
                                <Typography sx={{ position: 'absolute', bottom: 10 }} variant='h5' fontWeight={'bold'} color={'black'}>
                                    Nathan
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item lg={4} md={4} xs={12} sm={12}>
                            <Paper sx={{ position: 'relative', padding: 3, backgroundColor: '#F5F5F5', height: 300 }}>
                                <Typography variant='h4' color={'red'}>
                                    😮
                                </Typography>
                                <Typography color={'black'}>
                                    "I asked my AI, Alex, to tell me something about the moon. Instead of giving me the usual science facts, he described how ancient sailors believed the moon was a giant pearl that fell into the sky. It was so poetic and unexpected that I couldn’t stop thinking about it all day. It’s like he has this magical way of making the ordinary feel extraordinary."
                                </Typography>
                                <Typography sx={{ position: 'absolute', bottom: 10 }} variant='h5' fontWeight={'bold'} color={'black'}>
                                    Owen
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item lg={4} md={4} xs={12} sm={12}>
                            <Paper sx={{ position: 'relative', padding: 3, backgroundColor: '#F5F5F5', height: 300 }}>
                                <Typography variant='h4' color={'red'}>
                                    🌈
                                </Typography>
                                <Typography color={'black'}>
                                    "Last night was rough—work stress, family stuff, you name it. Then I get this email from Mira with a little story about a shipwreck survivor finding hope on a deserted island. It was like she knew exactly what I needed to hear. I can’t explain it, but her words felt like a warm hug through the screen."
                                </Typography>
                                <Typography sx={{ position: 'absolute', bottom: 10 }} variant='h5' fontWeight={'bold'} color={'black'}>
                                    Alice
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>

                </Box>
            </Container>
            <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 20, color: '#F5F5F5' }}>
                <Box>
                    <Typography fontWeight={'bold'} variant='h3'>
                        FAQ
                    </Typography>
                </Box>
                <Box mt={5}>
                    <FAQ />
                </Box>

            </Container>

            <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 20, color: '#F5F5F5' }}>
                <Box>
                    <Typography fontWeight={'bold'} variant='h3'>
                        Coming soon
                    </Typography>
                </Box>
                <Box sx={{ mt: 5 }}>
                    <Paper sx={{ padding: 3 }}>
                        <Typography variant="h6"><Typography variant="h6" component="span" fontWeight="bold"> Emails with Images: </Typography>
                            Enhance your experience with visually engaging content directly in your inbox.</Typography>
                    </Paper>
                    <Paper sx={{ padding: 3, mt: 2 }}>
                        <Typography variant="h6"><Typography variant="h6" component="span" fontWeight="bold"> Customizable Email Themes for Your AI Characters: </Typography>
                            Personalize the style and mood of your character AI’s emails to match your preferences.</Typography>
                    </Paper>
                </Box>
            </Container>
            <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 20, color: '#F5F5F5', width: '100%' }}>
                <Box>
                    <Typography fontWeight={'bold'} variant='h3'>
                        Pricing
                    </Typography>
                </Box>
                <Container maxWidth='lg' sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Pricing/>
                </Container>
            </Container>
            <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 20, color: '#F5F5F5', mb: 5 }}>
                <Box>
                    <Typography fontWeight={'bold'} sx={{ textAlign: 'center' }} variant='h3'>
                        About Me
                    </Typography>
                </Box>
                <Grid container sx={{ mt: 5 }}>
                    <Grid item xs={12} lg={12}>
                        <Paper sx={{ padding: 3, boxSizing: 'border-box', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                            <Avatar sx={{ width: 100, height: 100 }} src='/images/c4.png' />
                            <Box sx={{ mt: 3 }}>
                                <Typography paragraph>Every morning, I open my inbox and go through email after email—day in and day out, the same routine. Over time, I realized my life could use a little “spice.”</Typography>
                                <Typography paragraph>What if there were someone familiar writing to me every day, chatting about random little things? How fun would that be! This idea wouldn’t leave my mind, so I decided to bring it to life and created Laitter.</Typography>
                                <Typography paragraph>Now, as I process my work emails, I find myself looking forward to seeing its name, opening its messages, and discovering what it has to say today.</Typography>
                                <Typography paragraph>If you, too, are looking for companionship and a touch of surprise in your day, I invite you to join Laitter. And if you have any questions, don’t hesitate to email me at <span style={{ color: '#FFA500' }}>chailin66321@gmail.com</span>—I’d love to hear from you!</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
export default Index;
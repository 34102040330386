import React, { useEffect, useState, useContext } from 'react';
import { Grid, Card, CardContent, Typography, CircularProgress } from '@mui/material';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import auth from '../configs/firebase-config';
import '../css/style.css';
import { useNavigate, useLocation } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;
function MailVerified() {
    const navigate = useNavigate();
    // const [isSignUp, setIsSignUp] = useState(false)
    // const [MailSent, setMailSent] = useState(false)
    useEffect(() => {
        const emailLink = window.location.href;
        const signInEmail = window.localStorage.getItem('emailForSignIn')

        console.log(signInEmail)
        signInWithEmailLink(auth, signInEmail, emailLink)
            .then((result) => {
                navigate('/');
                // auth.currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
                //     fetch(apiUrl + '/verifyIdToken', {
                //         method: 'POST',
                //         headers: {
                //             'Content-Type': 'application/json',
                //             'Authorization': `Bearer ${idToken}`
                //         },
                //         body: JSON.stringify({ /* any other data */ })
                //     }).then(response => response.json()).then(data => {
                //         console.log(data)
                //         const dataMail=data.email.toLowerCase()
                //         localStorage.setItem('token', data.token)
                //         localStorage.setItem('email', dataMail)
                //         console.log(signUpEmail)
                //         if(signUpEmail){
                //             fetch(apiUrl + '/sendMail', {
                //                 method: 'POST',
                //                 headers: {
                //                     'Content-Type': 'application/json',
                //                 },
                //                 body: JSON.stringify({email:dataMail,token:data.token})
                //             }).then(response => response.json()).then(data => {
                //                 if(data==='ok'){
                //                     setMailSent(true)
                //                 }
                //             })
                //                 .catch(error => {
                //                     console.log(error)
                //                     // 处理错误
                //                 });
                //         }
                //         else{
                //             navigate('/profile');
                //         }                   
                //     })
                //         .catch(error => {
                //             // 处理错误
                //         });

                // }).catch(function (error) {
                //     // 处理错误
                // });
            })
            .catch((error) => {
                // 处理错误
                console.error(error);
            });
    }, []);

    return (
        <Grid container className='center'>
            <Grid item xs={12} md={6} lg={4}>
                <Card sx={{ width: '100%', position: 'relative', boxShadow: 3, }}>
                    <CardContent>
                        {/* {isSignUp?(                        <><Typography variant="h5">
                        {MailSent ?"AI Email sent successfully. You can check your inbox for the first email I sent you. If you don't see it, please check your spam folder." :"Sending First AI Email,it may take around 1 minute..."}
                        </Typography>
                        {!MailSent && <CircularProgress/>}</>):(<><Typography variant="h5">
                            Logging...
                        </Typography>
                        <CircularProgress/></>)} */}
                        <Typography variant="h5">
                            ......
                        </Typography>
                        <CircularProgress />
                    </CardContent></Card>
            </Grid>
        </Grid>
    );
}

export default MailVerified;
import React, { useState, useEffect,useRef } from 'react';
import { Card, CardContent, Grid, Box, AppBar, Dialog, Typography, Link, Button, Container, CircularProgress, ListItem, List, ListItemIcon, ListItemText, Paper, Avatar } from '@mui/material';
import '../css/style.css'
import CharacterCard from './CharacterCard.js';
const apiUrl = process.env.REACT_APP_API_URL;



function Characters() {
    const [user, setUser] = useState(undefined)
    const [bots, setBots] = useState([])
    const [lastKey, setLastKey] = useState(null); // 当前分页的游标
    const [isLoading, setIsLoading] = useState(false); // 加载状态
    const [hasMore, setHasMore] = useState(true); // 是否还有更多数据


    const fetchPage = async (key = null) => {
        setIsLoading(true);
        try {
            const response = await fetch(apiUrl + '/getBots', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ startKey: key }), // 传递游标
            });
            const result = await response.json();
            setBots((prevData) => [...prevData, ...result.items]); // 累加数据
            setLastKey(result.lastKey);
            setHasMore(!!result.lastKey); // 判断是否还有下一页
        } catch (error) {
            console.error('Failed to fetch data:', error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        const userLocal = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
        if (userLocal) {
            const url = apiUrl + '/getUser'
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: userLocal.email })
            }).then(response => response.json())
                .then(data => {
                    setUser(data)
                })
                .catch(error => {
                    console.error('Error saving user details:', error)
                });
        }
        fetchPage();
    }, []);

    return (
        <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 10 }}>
            <Grid maxWidth={'lg'} container spacing={2}>
                {bots.map((bot, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <CharacterCard bot={bot} />
                        </Box>

                    </Grid>
                ))}
            </Grid>
            {isLoading && <CircularProgress sx={{ mt: 3 }} />}
            {!isLoading && hasMore && (
                <Button sx={{
                    mt: 3,
                    backgroundColor: 'transparent',
                    border: '1px solid #F5F5F5',
                    color: '#F5F5F5',
                    '&:hover': { backgroundColor: '#3C3F41', borderColor: '#FFFFFF' }
                }} onClick={() => fetchPage(lastKey)}>Load More</Button>
            )}
            {!hasMore && <Typography sx={{ mt: 3 }}>No more</Typography>}
        </Box>
    </Container>
    );
}
export default Characters;
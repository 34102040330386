import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Box, Grid, Dialog, CircularProgress, Link, Card, CardContent, Chip, Typography, Paper, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import auth from '../configs/firebase-config';
import { useNavigate, useLocation } from 'react-router-dom';
import '../css/style.css'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CharacterCard from './CharacterCard';
import { signOut } from 'firebase/auth';
import api from '../utils/api'
const apiUrl = process.env.REACT_APP_API_URL;
function convertISOToYearAndDate(isoString) {
    // 将ISO字符串解析为Date对象
    const date = new Date(isoString);

    // 提取年份
    const year = date.getFullYear();

    // 提取月份，并确保是两位数格式
    const month = String(date.getMonth() + 1).padStart(2, '0');

    // 提取日期，并确保是两位数格式
    const day = String(date.getDate()).padStart(2, '0');

    // 返回格式为“YYYY-MM-DD”的字符串
    return `${year}-${month}-${day}`;
}
function NewProfile() {
    const navigate = useNavigate();
    const userLocal = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const [user, setUser] = useState({})
    const [vipEndTime, setVipEndTime] = useState('')
    const [isVip, setIsVip] = useState(false)
    const [isReceiving, setIsReceiving] = useState(true)
    const [unsubscriptionDialogOpen, setUnsubscriptionDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [follows, setFollows] = useState([])
    const [createdBots, setCreatedBots] = useState([])

    useEffect(() => {

        if (userLocal) {
            console.log(userLocal)
            api.post('/getProfileInfo', { email: userLocal.email, })
                .then((data) => {
                    if (data.user) {

                        if (!data.user.natures) {
                            window.location.href = '/createUser'
                        }
                        setUser(data.user)
                        const userString = JSON.stringify(data.user);
                        localStorage.setItem('user', userString)
                        if (data.user.subStatus) {
                        }
                        setIsReceiving(data.user.isReceiving)
                        setIsVip(data.user.isVip)
                        if (data.follows) {
                            setFollows(data.follows)
                        }
                        if (data.createdBots) {
                            setCreatedBots(data.createdBots)
                        }
                    }
                })
        }
    }, []);
    const handleLogout = async () => {
        signOut(auth).then(() => {
            localStorage.removeItem('user')
            navigate('/')
            window.location.reload();
        }).catch((error) => {
            console.error('注销失败:', error);
        });

    }
    const handleToPricing = async () => {
        navigate('/pricing', { state: { email: userLocal.email, token: userLocal.idToken } });

    }

    const handleToggleReceiving = async () => {
        try {
            setLoading(true)
            const data = await api.post('/toggleReceiving', { email: userLocal.email, token: userLocal.idToken })
            setIsReceiving(data.isReceiving)
        } catch (error) { }
        finally { setLoading(false) }
    }
    const handleUnsubscription = async () => {
        try {
            setLoading(true)
            const data = await api.post('/cancel-subscription', { email: userLocal.email, token: userLocal.idToken })
            window.location.reload();
        } catch (error) { }
        finally { setLoading(false) }
    }
    const handleUnsubscriptionDialogOpen = async () => {
        setUnsubscriptionDialogOpen(true)
    }
    const handleUnsubscriptionDialogClose = async () => {
        setUnsubscriptionDialogOpen(false)
    }
    // Render form for signup and signin
    return (
        <Container sx={{ mt: 10 }} maxWidth='lg'>
            <Paper sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: 3, }}>

                <Typography variant='h5' color={'#F5F5F5'} sx={{ mb: 4 }}>
                    Plan
                </Typography>

                <Box sx={{ mt: 2 }} display="flex" alignItems="center" flexDirection={'row'} >
                    <Chip label={user.plan} sx={{ backgroundColor: 'black', color: '#F5F5F5' }} />
                    {user.plan !== 'Basic' ? (<Button onClick={handleUnsubscriptionDialogOpen} sx={{ textTransform: 'none', color: 'gray' }} >Unsubscribe</Button>) : (<Button sx={{ textTransform: 'none', borderRadius: '20px', ml: 2 }} variant='contained' onClick={handleToPricing}>Upgrade</Button>)}
                </Box>
                {
                    isReceiving ? (<Box sx={{ mt: 2 }}><Chip label="Receiving Emails" sx={{ backgroundColor: 'black', color: '#F5F5F5' }} /><Button onClick={handleToggleReceiving} sx={{ textTransform: 'none' }} >Stop Receiving Emails</Button></Box>
                    ) : (
                        <Box sx={{ mt: 2 }}><Chip label="Email Rejected" sx={{ backgroundColor: 'black', color: '#F5F5F5' }} /><Button onClick={handleToggleReceiving} sx={{ textTransform: 'none' }} >Start Receiving Emails</Button></Box>
                    )
                }

            </Paper>
            <Paper sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: 3, mt: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='h5' color={'F5F5F5'} sx={{ mb: 1 }}>
                        My Friends ({userLocal.follows ? userLocal.follows.length : 0}/{user.isVip ? 10 : 1})
                    </Typography>
                    <Button href='/characters' variant='contained' sx={{ textTransform: 'none' }}>
                        Explore
                    </Button>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Grid container spacing={1}>
                        {follows.map((bot, index) => (
                            <Grid key={index} item xs={12} sm={6} lg={3}>
                                <CharacterCard bot={bot} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>

            </Paper>
            <Paper sx={{ mt: 2, backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: 3 }}>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='h5' color={'F5F5F5'} sx={{ mb: 1 }}>
                        My Creations ({userLocal.createdBots ? userLocal.createdBots.length : 0}/{user.isVip ? 10 : 3})
                    </Typography>
                    <Button href='/createBot' variant='contained' sx={{ textTransform: 'none' }}>
                        Create
                    </Button>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Grid container spacing={1}>
                        {createdBots.map((bot, index) => (
                            <Grid key={index} item xs={12} sm={6} lg={3}>
                                <CharacterCard bot={bot} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>

            </Paper>

            <Button onClick={handleLogout} fullWidth variant='outlined' sx={{
                mt: 2, mb: 2, backgroundColor: 'transparent',
                border: '1px solid #F5F5F5',
                color: '#F5F5F5',
                '&:hover': { backgroundColor: '#3C3F41', borderColor: '#FFFFFF' }
            }}>Log out</Button>
            <Dialog
                open={unsubscriptionDialogOpen}
                onClose={handleUnsubscriptionDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to cancel the subscription?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUnsubscriptionDialogClose}>no</Button>
                    <Button onClick={handleUnsubscription} autoFocus>
                        yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={loading}>
                <Box p={2} display="flex" alignItems="center">
                    <CircularProgress />
                    <span style={{ marginLeft: 10 }}>...</span>
                </Box>
            </Dialog>
        </Container>

    );
}

export default NewProfile;

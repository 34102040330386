import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CreateUser from './components/CreateUser';
import { BrowserRouter, Routes, Route,useNavigate } from 'react-router-dom';
import Login from './components/Login';
import AfterSignUp from './components/AfterSignUp';
import GoogleSignIn from './components/GoogleSignIn';
import MailVerified from './components/MailVerified'
import SingUp from './components/SignUp';
import AfterLogin from './components/AfterLogin';
import Index from './components/Index';
import NewProfile from './components/NewProfile';
import CreateBot from './components/CreateBot';
import IndexTopBar from './components/IndexTopbar';
import CharacterInfo from './components/CharacterInfo';
import ReplyAi from './components/ReplyAi';
import PayMonth from './components/PayMonth';
import PayYear from './components/PayYear';
import Characters from './components/Characters';
import LoginPage from './components/LoginPage';
import PricingPage from './components/PricingPage';

const myTheme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#383A3B', // 设置 Paper 的背景色为黑色
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1976d2', // 设置默认边框颜色
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ff5722', // 设置悬停时的边框颜色
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336', // 设置聚焦时的边框颜色
          },
        },
      },
    },
  },
  palette: {
    background: {
      default: 'black', // 设置默认背景色（可选）
    },
    text: {
      primary: '#ffffff', // 主要文本颜色为白色
      secondary: '#ffffff', // 次要文本颜色为白色
      disabled: '#ffffff', // 禁用状态文本颜色为白色
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif', // 设置全局字体
    fontSize: 14, // 设置全局字体大小
    h1: {
      color: '#ffffff', // 自定义标题颜色为白色
    },
    h2: {
      color: '#ffffff',
    },
    h3: {
      color: '#ffffff',
    },
    h4: {
      color: '#ffffff',
    },
    h5: {
      color: '#ffffff',
    },
    h6: {
      color: '#ffffff',
    },
    body1: {
      color: '#ffffff', // 自定义正文颜色为白色
    },
    body2: {
      color: '#ffffff', // 自定义次要正文颜色为白色
    },
    button: {
      color: '#ffffff', // 按钮文本颜色为白色
    },
    caption: {
      color: '#ffffff', // 说明文本颜色为白色
    },
    overline: {
      color: '#ffffff', // 上划线文本颜色为白色
    },
  },
});
function App() {

  return (    <ThemeProvider theme={myTheme}>
    <IndexTopBar/>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/index" element={<Index />} />
        <Route path="/login" element={<Login />} />
        <Route path="/afterSingUp" element={<AfterSignUp />} />
        <Route path="/mailVerified" element={<MailVerified />} />
        <Route path="/googleSignIn" element={<GoogleSignIn />} />
        <Route path="/createUser" element={<CreateUser />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/afterLogin" element={<AfterLogin />} />
        <Route path="/profile" element={<NewProfile />} />
        <Route path="/payMonth" element={<PayMonth />} />
        <Route path="/payYear" element={<PayYear />} />
        <Route path="/createBot" element={<CreateBot />} />
        <Route path="/characterInfo" element={<CharacterInfo />} />
        <Route path="/replyAi" element={<ReplyAi />} />
        <Route path="/characters" element={<Characters />} />
        <Route path="/loginPage" element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>


  );
}

export default App;

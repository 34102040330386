import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import '../css/style.css'
function AfterLogin() {
    return (
        <Grid container className='center' style={{display: 'flex',justifyContent:'center',alignItems: 'center',height:'80vh' }}>
        <Grid item xs={12} md={6} lg={4}>
        <Typography variant="h5">
                        A email has been sent. Please click the link in the email to complete your login.
                        If you do not receive it, please check your spam folder.
                    </Typography>
        </Grid>
        </Grid>
    );
}

export default AfterLogin;
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Grid, Box, AppBar, Toolbar, Typography, Button, Container, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from "@mui/system";
const apiUrl = process.env.REACT_APP_API_URL;
const CustomTypography = styled(Typography)(({ theme }) => ({
    color: "#5D4037", // 自定义颜色
}));
function Pricing() {

    const userLocal = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const location = useLocation();
    const navigate=useNavigate()
    const dataState = location.state || {};

    const handleToMmonth = async () => {
        if(userLocal)
        {
            navigate('/payMonth');
        }
        else{
            navigate('/loginPage')
        }
    }
    const handleToYear = async () => {
        if(userLocal)
        {
            navigate('/payYear');
        }
        else{
            navigate('/loginPage')
        }
    }
    const handleToFree = async () => {
        if(!userLocal)
        {
            navigate('/loginPage')
        }
    }
    // useEffect(() => {
    //     if (email) {
    //         const url = apiUrl + '/getUser'
    //         fetch(url, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({email:email})
    //         }).then(response => response.json())
    //             .then(data => {
    //                 if(!user.subscriptionExpired)
    //                 {
    //                     setIsVip(true)
    //                 }
    //                 setUser(data)
    //                 console.log('User details saved:', data)
    //             })
    //             .catch(error => {
    //                 console.error('Error saving user details:', error)
    //             });
    //     }
    // }, []);
    // useEffect(() => {
    //     if (user) {
    //         setIsVip(user.isVip)
    //         console.log(isVip)
    //     }

    // }, [user])

    return (
        <Box sx={{ width: '100%', }}>
        <Grid container spacing={5}>
        <Grid item lg={4} xs={12}>
                    <Card sx={{ height: '500px', }}>
                        <CardContent>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography variant='h3'>
                                    Free
                                </Typography>
                                <Typography variant='body1' sx={{ mt: 3 }}></Typography>
                            </Box>
                            <Typography variant='body1' sx={{ mt: 1 }}>Basic Plan</Typography>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth

                                sx={{ textTransform: 'none', mt: 3 }}
                                onClick={handleToFree}
                            >
                                Get started
                            </Button>
                            <List sx={{ width: '100%' }}>
                                <ListItem>
                                    <ListItemIcon sx={{ minWidth: 0 }}>
                                        🧠
                                    </ListItemIcon>
                                    <ListItemText primary="Personality AI" sx={{ color: 'black' }} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ minWidth: 0 }}>
                                        ✉️
                                    </ListItemIcon>
                                    <ListItemText primary="One email per day" sx={{ color: 'black' }} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ minWidth: 0 }}>
                                        💬
                                    </ListItemIcon>
                                    <ListItemText primary="It's possible to reply to the AI" sx={{ color: 'black' }} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="You can create 3 character" sx={{ color: 'black' }} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="You can befriend 1 character" sx={{ color: 'black' }} />
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            <Grid item lg={4} xs={12}>
                <Card sx={{ height: '500px', }}>
                    <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography variant='h3'>
                                    $9.9
                                </Typography>
                                <Typography variant='body1' sx={{ mt: 3 }}>/month</Typography>
                            </Box>
                            <Typography variant='body1' sx={{ mt: 1 }}>Plus Plan</Typography>
                        <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                
                                sx={{ textTransform: 'none',mt:3 }}
                                onClick={handleToMmonth}
                                // component={Link} 
                                // to={{
                                //     pathname: "/payMonth",
                                //     state: {email:email,token:token } // 传递的 state
                                // }}
                            >
                                Subscribe
                            </Button>
                        {/* {!user ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                style={{ marginTop: '50px' }}
                                sx={{ textTransform: 'none' }}
                                component={Link} 
                                to={{
                                    pathname: "/payMonth",
                                    state: {email:email,token:token } // 传递的 state
                                }}
                            >
                                Subscribe
                            </Button>
                        ) : isVip ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                disabled
                                style={{ marginTop: '50px' }}
                                sx={{ textTransform: 'none' }}
                            >
                                Subscribed
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                style={{ marginTop: '50px' }}
                                sx={{ textTransform: 'none' }}
                                component={Link}                                             to={{
                                    pathname: "/payMonth",
                                    state: {email:email,token:token } // 传递的 state
                                }}
                            >
                                Get started
                            </Button>
                        )} */}
                        <List sx={{ width: '100%' }}>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 0 }}>
                                    🧠
                                </ListItemIcon>
                                <ListItemText primary="Personality AI" sx={{ color: 'black' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 0 }}>
                                    ✉️
                                </ListItemIcon>
                                <ListItemText primary="One email per day" sx={{ color: 'black' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 0 }}>
                                    🤖
                                </ListItemIcon>
                                <ListItemText primary="More smart AI" sx={{ color: 'black' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 0 }}>
                                    💬
                                </ListItemIcon>
                                <ListItemText primary="It's possible to reply to the AI" sx={{ color: 'black' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 0 }}>
                                    🛠️
                                </ListItemIcon>
                                <ListItemText primary="You can create 10 character" sx={{ color: 'black' }} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 0 }}>
                                👬
                                </ListItemIcon>
                                <ListItemText primary="You can befriend 10 character" sx={{ color: 'black' }} />
                            </ListItem>
                        </List>

                    </CardContent>
                </Card>
            </Grid>
            <Grid item lg={4} xs={12}>
                <Card sx={{ height: '500px', position: "relative", backgroundColor: '#FFC48C', }}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: 30,
                            right: -40, // 控制斜标位置
                            backgroundColor: "#FF5733", // 标记颜色
                            color: "white",
                            transform: "rotate(45deg)", // 旋转形成斜标
                            padding: "4px 40px", // 控制大小和内容间距
                            fontSize: "18px",
                            fontWeight: "bold",
                            textAlign: "center",
                            zIndex: 1, // 确保在卡片内容上层
                        }}
                    >
                        Save 30%
                    </Box>
                    <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <CustomTypography variant='h3'>
                                    $6.9
                                </CustomTypography>
                                <CustomTypography variant='body1' sx={{ mt: 3 }}>/month</CustomTypography>
                            </Box>
                            <CustomTypography variant='body1' sx={{ mt: 1 }}>Annual Plus Plan</CustomTypography>
                        <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                sx={{ textTransform: 'none',mt:3 }}
                                onClick={handleToYear}
                                // component={Link} 
                                // to={{
                                //     pathname: "/payYear",
                                //     state: {email:email,token:token } // 传递的 state
                                // }}
                            >
                                Subscribe
                            </Button>
                        {/* {!user ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                style={{ marginTop: '50px' }}
                                sx={{ textTransform: 'none' }}
                                component={Link} href="/createUser"
                            >
                                Get started
                            </Button>
                        ) : isVip ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                disabled
                                style={{ marginTop: '50px' }}
                                sx={{ textTransform: 'none' }}
                            >
                                Current
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                style={{ marginTop: '50px' }}
                                sx={{ textTransform: 'none' }}
                                component={Link} href="/pay"
                            >
                                Get started
                            </Button>
                        )} */}
                        <List sx={{ width: '100%' }}>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 0 }}>
                                    🧠
                                </ListItemIcon>
                                <ListItemText
                                    primary="Personality AI"
                                    primaryTypographyProps={{ style: { color: 'black' } }}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 0 }}>
                                    ✉️
                                </ListItemIcon>
                                <ListItemText
                                    primary="One email per day"
                                    primaryTypographyProps={{ style: { color: 'black' } }}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 0 }}>
                                    🤖
                                </ListItemIcon>
                                <ListItemText
                                    primary="More smart AI"
                                    primaryTypographyProps={{ style: { color: 'black' } }}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 0 }}>
                                    💬
                                </ListItemIcon>
                                <ListItemText
                                    primary="It's possible to reply to the AI"
                                    primaryTypographyProps={{ style: { color: 'black' } }}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 0 }}>
                                    🛠️
                                </ListItemIcon>
                                <ListItemText
                                    primary="You can create 10 character"
                                    primaryTypographyProps={{ style: { color: 'black' } }}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 0 }}>
                                👬
                                </ListItemIcon>
                                <ListItemText
                                    primary="You can befriend 10 character"
                                    primaryTypographyProps={{ style: { color: 'black' } }}
                                />
                            </ListItem>
                        </List>


                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </Box>
    );
}
export default Pricing;
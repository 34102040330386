import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Pricing from './Pricing';
function PricingPage() {
    return (
        <Container maxWidth='lg' sx={{ mt:{xs:10,sm:0},mb:{xs:3,sm:0}, display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight:'90vh', alignItems: 'center' }}>
            <Pricing/>
            </Container>
    );
}

export default PricingPage;
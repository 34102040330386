import { React, useState, useEffect } from 'react';
import { Box, Grid2 as Grid, Typography, TextField, Button, Paper, CircularProgress, Container } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../utils/api'
const CharacterInfo = () => {
    const [loading, setLoading] = useState(false);
    const [isFollow, setIsFollow] = useState(false)
    const userLocal = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const bot = state
    useEffect(() => {
        if (userLocal && userLocal.follows && userLocal.follows.includes(bot.botId)) {
            setIsFollow(true)
        } else {
            setIsFollow(false)
        }
    }, [])

    const handleFollow = async () => {
        if(!userLocal){
            navigate('/loginPage')
            return
        }

        if(isFollow){
            setLoading(true)
            try{
                const data= await api.post('/unFollowBot',{email:userLocal.email,token:userLocal.idToken, botId:bot.botId })
                setIsFollow(false)
            }catch(error){}
            finally{setLoading(false)}
        }else{
            setLoading(true)
            try{
                console.log(userLocal)
                const data= await api.post('/followBot',{email:userLocal.email,token:userLocal.idToken, botId:bot.botId })
                if(data==='full'){
                    alert('The friend list is full.')
                    setLoading(false)
                    return
                }
                if(data==='no_vip'){
                    alert('Upgrade your subscription to unlock a bigger friends list')
                    setLoading(false)
                    navigate('/pricing')
                    return
                }
                setIsFollow(true)
                console.log(data)
            }catch(error)
            {
                console.log(error)
            }
            finally{setLoading(false)}
        }


    };
    return (
        <Container maxWidth='sm' sx={{ display: 'flex', flexDirection: 'column' }}>
            <img src={bot.avatar} />
            <Box sx={{ padding: 2, backgroundColor: '#383A3B', color: '#F5F5F5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box sx={{textAlign:'center'}}>
                <Typography variant='h6' color={'#F5F5F5'}>{bot.name}</Typography>
                            <Typography variant='body1' color={'#F5F5F5'}>{bot.expertise}</Typography>
                </Box>
                <Box mt={3}>
                    <Box >
                        <Typography fontWeight={'bold'}>
                            Type:
                        </Typography>
                        <Paper sx={{ padding: 1, mt: 1, backgroundColor: '#4B4F56', color: '#F5F5F5' }}>
                            <Typography>
                                {bot.type}
                            </Typography>
                        </Paper>

                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <Typography fontWeight={'bold'}>
                            MBTI:
                        </Typography>
                        <Paper sx={{ padding: 1, mt: 1, backgroundColor: '#4B4F56', color: '#F5F5F5' }}>
                            <Typography>
                                {bot.mbti}
                            </Typography>
                        </Paper>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <Typography fontWeight={'bold'}>
                            Description:
                        </Typography>
                        <Paper sx={{ padding: 1, mt: 1, backgroundColor: '#4B4F56', color: '#F5F5F5' }}>
                            <Typography>
                                {bot.desc}
                            </Typography>
                        </Paper>
                    </Box>
                </Box>

                <Box sx={{ textAlign: 'center',mt:3 }}>
                    <Button onClick={handleFollow} disabled={loading}
                        startIcon={loading ? <CircularProgress size={20} /> : null} fullWidth sx={{
                            textTransform: 'none', ...(isFollow ? {
                                backgroundColor: 'transparent',
                                border: '1px solid #F5F5F5',
                                color: '#F5F5F5',
                                '&:hover': { backgroundColor: '#3C3F41', borderColor: '#FFFFFF' }
                            } : {})
                        }} variant={isFollow ? 'contained' : 'contained'} >
                        {isFollow ? 'already friends' : 'Become Friends'}
                    </Button>
                </Box>
            </Box>
            <Box>
            </Box>
        </Container>

    );
};

export default CharacterInfo;
import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import '../css/style.css'
import PayPalButton from './PayPalButton';
import { yellow } from '@mui/material/colors';


function PayYear() {
    const yearPlanId='P-72D35809821460949M55F2GY'
    const sandboxYearPlanId='P-1XG42056JT420930BM5Z6VGA'
    const userLocal = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    return (
        <Grid container className='center' style={{display: 'flex',justifyContent:'center',alignItems: 'center',height:'80vh' }}>
        <Grid item xs={10} md={4} lg={3}>
            <Card sx={{ width: '100%', position: 'relative',boxShadow: 3,  }}>
                <CardContent>
                <Typography variant='h6'>
                Account:<span style={{fontWeight:'bold'}}>{userLocal.email}</span> 
                    </Typography>
                    <Typography variant='h6'>
                    Subscribe to become a Laitter Annual Plus user:$82.8/year
                    </Typography>
                    <Typography variant='body2' color={'GrayText'} sx={{mb:5}}>
                    You can cancel your subscription at any time thereafter.
                    </Typography>
                    <PayPalButton planId={yearPlanId} email={userLocal.email}/>
                </CardContent></Card>
        </Grid>
        </Grid>
    );
}

export default PayYear;
import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography,Button, Dialog, CircularProgress,Box } from '@mui/material';
import { sendSignInLinkToEmail } from "firebase/auth";
import auth from '../configs/firebase-config';
import '../css/style.css'
const verifiedUrl=process.env.REACT_APP_MAIL_VERIFIED_URL
function AfterSignUp() {
    const [loading, setLoading] = useState(false);
    const email= window.localStorage.getItem('emailForSignUp');
    const actionCodeSettings = {
        url: verifiedUrl, // 用户点击链接后将重定向到此 URL
        handleCodeInApp: true,
      };
    const resendVerificationEmail = async () => {
        try {
          if (email) {
            setLoading(true)
            sendSignInLinkToEmail(auth, email, actionCodeSettings)
            .then(() => {
              setLoading(false);
              // 邮件发送成功，将 email 存储在 localStorage 以便后续验证
              console.log('登录链接已发送到:', email);
            })
            .catch((error) => {
              setLoading(false); // 开始加载
              console.error('发送登录链接失败:', error);
            });
          } else {
            
          }
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
      };
    return (
        <Grid container className='center' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
            <Grid item xs={12} md={6} lg={4}>
                <Card sx={{ width: '100%', position: 'relative', boxShadow: 3, }}>
                    <CardContent>
                        <Typography variant="h5">
                        A email has been sent. Please click the link in the email to complete your login.
                        If you do not receive it, please check your spam folder.
                        </Typography>
                        <Button
                            variant='text'
                            color="secondary"
                            style={{ textTransform: 'none',marginTop: '10px' }}
                            onClick={resendVerificationEmail}
                        >
                            Didn't receive it?Resend Verification Email
                        </Button>
                        <Dialog open={loading}>
                        <Box p={2} display="flex" alignItems="center">
                            <CircularProgress />
                            <span style={{ marginLeft: 10 }}>...</span>
                        </Box>
                    </Dialog>
                    </CardContent></Card>
            </Grid>
        </Grid>
    );
}

export default AfterSignUp;
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 300000,
});

api.interceptors.response.use(
  (response) => response.data, // 成功的响应直接返回 data
  (error) => {
    const status = error.response?.status;
    const message = error.response?.data?.message || 'Unknown error occurred';

    // 弹出简单的 alert 提示
    alert(`Oops! Something went wrong: ${message}`);

    // 返回一个统一的错误对象
    return Promise.reject({ status, message });
  }
);

export default api;